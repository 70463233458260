import React from "react";
import "./style.scss";
import Layout from "../../components/layout";

class MrRobot extends React.Component {
  constructor(props) {
    super(props);

    this.state = { moving: false };
  }

  move = element => {
    if (!this.state.moving) {
      this.setState({ moving: true });
      element.classList.add("moveIt");

      setTimeout(() => {
        element.classList.remove("moveIt");
        this.setState({ moving: false });
      }, 500);
    }
  };

  walk = event => {
    let mrRobot = document.getElementById("mrrobot");
    let clientRect = mrRobot.getBoundingClientRect();
    //console.log(`x ${clientRect.x} y ${clientRect.y} innerWidth ${window.innerWidth}`);
    let keys = ["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft"];

    if (event.key === keys[2]) {
      this.move(mrRobot);
      if (mrRobot.style.left.length === 0) {
        mrRobot.style.left = "50px";
      } else if (clientRect.x > window.innerWidth - 200) {
        mrRobot.style.left = `-50px`;
      } else {
        let currentPosition = this.extractNumber(mrRobot.style.left);
        currentPosition += 50;
        mrRobot.style.left = `${currentPosition.toString()}px`;
      }
    }

    if (event.key === keys[3]) {
      this.move(mrRobot);
      if (clientRect.x < -50) {
        mrRobot.style.left = `${window.innerWidth - 200}px`;
      } else if (mrRobot.style.left.length === 0) {
        mrRobot.style.left = "-50px";
      } else {
        let currentPosition = this.extractNumber(mrRobot.style.left);
        currentPosition -= 50;
        mrRobot.style.left = `${currentPosition.toString()}px`;
      }
    }
  };

  extractNumber = cssValue => {
    let size = cssValue.indexOf("px");
    let num = cssValue.slice(0, size);
    return parseInt(num);
  };

  componentDidMount() {
    document.addEventListener("keydown", this.walk);
  }

  render() {
    return (
      <Layout>
        <div style={{ marginTop: "240px", position: "relative" }}>
          <div className="start" id="mrrobot" />
        </div>
      </Layout>
    );
  }
}

export default MrRobot;
